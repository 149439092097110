import '../../../../src/components/protons/icon/Icon';
import '../../atoms/date-from-to/DateFromTo';
import '../../atoms/label/Label';
import '../../atoms/input/Input';
import '../../atoms/tool-tip/ToolTip';
import type { DefaultComponentType } from '../../../../src/utils/types';
import type { IconProps } from '../../../../src/components/protons/icon/Icon';
import type { LabelProps } from '../../atoms/label/Label';
import type Input from '../../atoms/input/Input';
import type { InputProps } from '../../atoms/input/Input';
import type { SelectProps } from '../../atoms/select/Select';
import type { ToolTipProps } from '../../atoms/tool-tip/ToolTip';
import './FormField.pcss';

export type SelectFormField = SelectProps & {
    label: LabelProps;
};

export enum FormFieldEvents {
    IS_VALID = 'formFieldValidity'
}

type HelpProps = {
    text: string;
    secure?: boolean;
    link?: {
        href: string;
        label: string;
    };
};

export type FormFieldProps = DefaultComponentType & {
    input: InputProps;
    label: LabelProps;
    help?: HelpProps;
    tooltip?: ToolTipProps;
    errorText?: string;
    icon?: IconProps;
    clearable?: boolean;
    minimal?: boolean;
    attached?: boolean;
    horizontal?: boolean;
};

const fieldSelector: string =
    '[is="nh-input"], [is="nh-select"], [is="nh-textarea"], [data-role="input"]';

export default class FormField extends HTMLElement {
    #field: Input | HTMLSelectElement | HTMLTextAreaElement | null =
        this.querySelector(fieldSelector);

    get field(): Input | HTMLSelectElement | HTMLTextAreaElement | null {
        return this.#field;
    }

    #clearable: boolean = false;

    #clearButton: HTMLButtonElement | null = null;

    #disabled: boolean = this.#field?.disabled ?? false;

    get disabled(): boolean {
        return this.#disabled;
    }

    set disabled(value: boolean) {
        this.#disabled = value;

        this.classList.toggle(FormField.#disabledClass, value);

        if (this.#field) {
            this.#field.disabled = value;
        }

        if (value) {
            this.classList.remove(FormField.#errorClass);
        }
    }

    static readonly #errorClass: string = 'nh-form-field--error';

    static readonly #disabledClass: string = 'nh-form-field--disabled';

    set clearable(clearable: boolean) {
        this.#clearable = clearable;
    }

    get clearable(): boolean {
        return this.#clearable;
    }

    get value(): string {
        return this.#field?.value || '';
    }

    get type(): string {
        if (!this.#field) {
            return '';
        }

        if (this.#field instanceof HTMLTextAreaElement) {
            return 'textarea';
        }

        if (this.#field instanceof HTMLSelectElement) {
            return 'select';
        }

        if (this.#field.type) {
            return this.#field.type;
        }

        return '';
    }

    constructor(props: FormFieldProps) {
        super();

        if (props && props.errorText) {
            this.classList.add(FormField.#errorClass);
        }
    }

    protected connectedCallback(): void {
        this.#field = this.querySelector(fieldSelector);
        this.disabled = this.#field?.disabled ?? false;
        this.#clearButton = this.querySelector('[data-role="clear"]');

        if (!this.#field) {
            return;
        }

        this.#clearButton?.addEventListener('click', this.#onClear);
        this.#field.addEventListener('cleared', this.#showOrHideClearButton);
        this.#field.addEventListener('input', this.#showOrHideClearButton);
        this.#field.addEventListener('blur', this.#showOrHideClearButton);
        this.#field.addEventListener('change', this.#onChange);
        this.#field.addEventListener('invalid', this.#onInvalid);
        this.#field.addEventListener('disabled', this.#onDisabled.bind(this));

        this.#showOrHideClearButton();
    }

    protected disconnectedCallback(): void {
        if (!this.#field) {
            return;
        }

        this.#clearButton?.removeEventListener('click', this.#onClear);
        this.#field.removeEventListener('cleared', this.#showOrHideClearButton);
        this.#field.removeEventListener('input', this.#showOrHideClearButton);
        this.#field.removeEventListener('blur', this.#showOrHideClearButton);
        this.#field.removeEventListener('change', this.#onChange);
        this.#field.removeEventListener('invalid', this.#onInvalid);
        this.#field.removeEventListener('disabled', this.#onDisabled.bind(this));
    }

    public clearErrorState(): void {
        this.classList.remove(FormField.#errorClass);

        const errorMessage: HTMLElement | null = this.querySelector('.nh-form-field__error-text');
        if (errorMessage) {
            errorMessage.innerText = '';
        }
    }

    #onDisabled(): void {
        this.#disabled = true;
    }

    #onClear = (event: MouseEvent): void => {
        event.preventDefault();
        event.stopPropagation();

        this.#field?.dispatchEvent(new Event('clear'));
    };

    #onChange = (): void => {
        if (this.#field?.validity.valid) {
            this.#onValid();
        }

        this.#showOrHideClearButton();
    };

    #showOrHideClearButton = (): void => {
        this.#clearButton?.toggleAttribute('hidden', !this.#field?.value);
        this.clearable = !!this.#field?.value;
    };

    #onValid = (): void => {
        this.clearErrorState();

        this.dispatchEvent(
            new CustomEvent(FormFieldEvents.IS_VALID, { detail: { isValid: true }, bubbles: true })
        );
    };

    #onInvalid = (): void => {
        this.classList.add(FormField.#errorClass);

        this.dispatchEvent(
            new CustomEvent(FormFieldEvents.IS_VALID, {
                detail: { isValid: false, message: this.#field?.validationMessage }
            })
        );

        const errorMessage: HTMLElement | null = this.querySelector('.nh-form-field__error-text');
        if (errorMessage && this.#field?.validationMessage && this.type !== 'checkbox') {
            errorMessage.innerText = this.#field.validationMessage;
        }
    };
}

if (!window.customElements.get('nh-form-field')) {
    window.customElements.define('nh-form-field', FormField);
}
